<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Button
            @click="previousList"
            icon="pi pi-arrow-circle-left"
            class="p-button-rounded p-button-primary"
          />
          <b class="toggleTile p-ml-2 p-pt-1">
            {{ title }}
          </b>
        </template>

        <template v-slot:right>
          <Button
            @click="opensearchList"
            icon="pi pi-search"
            class="p-button-rounded p-button-success p-mr-2"
          />
          <Button
            @click="loadList"
            icon="pi pi-refresh"
            class="p-button-rounded p-button-warning p-mr-2"
          />
          <Button
            icon="pi pi-arrow-circle-right"
            class="p-button-rounded p-button-primary"
            @click="nextList"
          />
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <div class="row p-m-0">
          <div class="col-md-9 p-p-0">
            <DataTable
              :value="lists"
              :scrollable="true"
              @page="onPage($event)"
              class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
            >
              <Column headerStyle="width: 150px" header="Logout">
                <template #body="slotProps">
                  <span v-if="slotProps.data.timeOut == '00:00:00'">
                    <input
                      type="radio"
                      name="logout"
                      style="width: 30px; height: 30px"
                      value=""
                      @click="logout(slotProps)"
                    />
                  </span>
                </template>
              </Column>
              <Column
                field="employeeId"
                headerStyle="width: 150px"
                header="Associate ID"
              ></Column>
              <Column
                field="branchId"
                headerStyle="width: 150px"
                header="Store Code"
              ></Column>
              <Column headerStyle="width: 200px" header="Associate Name">
                <template #body="slotProps">
                  {{ slotProps.data.employeeFirstname }}
                  {{ slotProps.data.employeeName }}
                </template>
              </Column>
              <Column headerStyle="width: 150px" header="Date">
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.date) }}
                </template>
              </Column>
              <Column headerStyle="width: 150px" header="Time in">
                <template #body="slotProps">
                  {{ formatTime(slotProps.data.timeIn) }}
                </template>
              </Column>
              <Column headerStyle="width: 150px" header="Image in">
                <template #body="slotProps">
                  <span
                    v-if="
                      slotProps.data.pictureIn != '' &&
                      slotProps.data.pictureIn != 'default.jpg'
                    "
                  >
                    <img
                      @click="previewImage(slotProps.data.pictureIn)"
                      :src="slotProps.data.pictureIn"
                      alt="Image In"
                      class="user-image"
                    />
                  </span>
                </template>
              </Column>
              <Column headerStyle="width: 150px" header="Time out">
                <template #body="slotProps">
                  <span v-if="slotProps.data.timeOut != '00:00:00'">
                    {{ formatTime(slotProps.data.timeOut) }}
                  </span>
                </template>
              </Column>
              <Column headerStyle="width: 150px" header="Image out">
                <template #body="slotProps">
                  <span
                    v-if="
                      slotProps.data.pictureOut != '' &&
                      slotProps.data.pictureOut != 'default.jpg'
                    "
                  >
                    <img
                      @click="previewImage(slotProps.data.pictureOut)"
                      :src="slotProps.data.pictureOut"
                      alt="Image Out"
                      class="user-image"
                    />
                  </span>
                </template>
              </Column>
              <Column headerStyle="width: 200px" header="Added By">
                <template #body="slotProps">
                  {{ slotProps.data.addedBy }}
                </template>
              </Column>
            </DataTable>
          </div>
          <div class="col-md-3 text-center camera-section p-m-0 p-p-2">
            <h6 v-if="cameraScreenAssociate != ''">
              {{ cameraScreenAssociate }}
            </h6>
            <p style="color: #c00; font-size: 18px" v-if="cameraFound == false">
              <i class="pi pi-camera"></i> No Camera is Deducted
            </p>
            <video
              id="webcam"
              autoplay
              playsinline
              width="220"
              height="160"
            ></video>
            <canvas id="canvas" class="d-none"></canvas>
            <div class="form-group p-mt-3">
              <input
                type="password"
                placeholder="Associate PIN"
                v-model="associatePIN"
                autoFocus
                class="form-control p-p-4"
              />
            </div>
            <div class="form-group text-center">
              <Button
                v-if="cameraScreenTile == 'Login'"
                label="Login"
                icon="pi pi-unlock"
                class="p-button-primary p-button-lg"
                :disabled="
                  associatePIN == '' || cameraFound == false ? true : false
                "
                @click="availableSurvey()"
              />
              <Button
                v-if="cameraScreenTile == 'Logout'"
                label="Logout"
                icon="pi pi-lock"
                class="p-button-danger p-button-lg"
                :disabled="
                  associatePIN == '' || cameraFound == false ? true : false
                "
                @click="logOutReq()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Dialog
    :maximizable="true"
    position="top"
    class="p-fluid"
    v-model:visible="previewDailog"
    :style="{ width: '600px' }"
    header="Associate Image"
  >
    <div class="confirmation-content">
      <img
        :src="previewPath"
        alt="associate login/logout Image"
        class="preview-image"
      />
    </div>
  </Dialog>

  <Dialog
    v-model:visible="searchDialog"
    :style="{ width: '50vw' }"
    :maximizable="true"
    position="top"
    class="p-fluid"
  >
    <template #header>
      <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
        Search Associates
      </h4>
    </template>
    <div class="p-field">
      <select class="form-control" v-model="searchList.filter">
        <option value="none"></option>
        <option value="Today">Today</option>
        <option value="This_Month">This Month</option>
        <option value="Last_Month">Last Month</option>
      </select>
    </div>
    <div class="p-field">
      <label for="name">OR Date From:</label>
      <input type="date" v-model.trim="searchList.date1" class="form-control" />
    </div>
    <div class="p-field">
      <label for="name">Date To:</label>
      <input type="date" v-model.trim="searchList.date2" class="form-control" />
    </div>
    <div class="p-field">
      <label for="storesList">Filter Stores</label>
      <MultiSelect
        class="multiselect-custom"
        v-model="searchList.assignedBranchId"
        emptyFilterMessage="No Store found"
        :options="myStores"
        scrollHeight="300px"
        placeholder="Select Stores"
      >
        <template #value="slotProps">
          <div
            class="country-item country-item-value"
            v-for="option of slotProps.value"
            :key="option.code"
          >
            <div>Store {{ option.mall }} | Code {{ option.code }}</div>
          </div>
          <template v-if="!slotProps.value || slotProps.value.length === 0">
            Select Stores
          </template>
        </template>
        <template #option="slotProps">
          <div class="country-item">
            <div>
              Store {{ slotProps.option.mall }} | Code
              {{ slotProps.option.code }}
            </div>
          </div>
        </template>
      </MultiSelect>
    </div>
    <template #footer>
      <Button
        type="submit"
        label="Search"
        icon="pi pi-search"
        class="p-button-primary"
        @click="loadList()"
      />
    </template>
  </Dialog>
  <Dialog
    v-model:visible="surveyDailog"
    :style="{ width: '50vw' }"
    :maximizable="true"
    position="top"
    class="p-fluid"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">Store Survey</h5>
    </template>
    <div class="p-field">
      <h5 for="name">{{ surveyList.surveyQuestion }}</h5>
    </div>
    <div class="p-field">
      <div
        class="p-field-radiobutton survey-question"
        v-if="surveyList.option1 != ''"
      >
        <RadioButton
          id="surveyOption1"
          name="surveyOption"
          :value="surveyList.option1"
          v-model="surveyList.answer"
        />
        <label for="surveyOption1">{{ surveyList.option1 }}</label>
      </div>
      <div
        class="p-field-radiobutton survey-question"
        v-if="surveyList.option2 != ''"
      >
        <RadioButton
          id="surveyOption2"
          name="surveyOption"
          :value="surveyList.option2"
          v-model="surveyList.answer"
        />
        <label for="surveyOption2">{{ surveyList.option2 }}</label>
      </div>
      <div
        class="p-field-radiobutton survey-question"
        v-if="surveyList.option3 != ''"
      >
        <RadioButton
          id="surveyOption3"
          name="surveyOption"
          :value="surveyList.option3"
          v-model="surveyList.answer"
        />
        <label for="surveyOption3">{{ surveyList.option3 }}</label>
      </div>
      <div
        class="p-field-radiobutton survey-question"
        v-if="surveyList.option4 != ''"
      >
        <RadioButton
          id="surveyOption4"
          name="surveyOption"
          :value="surveyList.option4"
          v-model="surveyList.answer"
        />
        <label for="surveyOption4">{{ surveyList.option4 }}</label>
      </div>
      <div
        class="p-field-radiobutton survey-question"
        v-if="surveyList.option5 != ''"
      >
        <RadioButton
          id="surveyOption5"
          name="surveyOption"
          :value="surveyList.option5"
          v-model="surveyList.answer"
        />
        <label for="surveyOption5">{{ surveyList.option5 }}</label>
      </div>
    </div>
    <template #footer>
      <Button
        type="button"
        label="Submit Answer"
        icon="pi pi-check-circle"
        class="p-button-success"
        @click="saveSurvey()"
      />
    </template>
  </Dialog>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AttendanceService from "../../service/AttendanceService";
import Toaster from "../../helpers/Toaster";
import Webcam from "webcam-easy";
import moment from "moment";
import { camelCase } from "lodash";
import { branchDetail } from "../../pages/announcements/IAnnounce";

@Options({
  components: {},
})
export default class Attendance extends Vue {
  private webcam;
  private associatePIN = "";
  private attendenceID = "";
  private cameraScreenTile = "Login";
  private cameraScreenAssociate = "Associate Login";
  private lists = [];
  private myStores: Array<branchDetail> = [];
  private attendence;
  private searchDialog = false;
  private previewDailog = false;
  private surveyDailog = false;
  private cameraFound = false;
  private previewPath = "";
  private previousDate = "";
  private nextDate = "";
  private title = "";
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    {
      label: "Attendance",
    },
  ];
  private toast;

  private searchList = {
    filter: "none",
    date1: "",
    date2: "",
    assignedBranchId: [],
  };

  private surveyList = {
    surveyId: "",
    surveyQuestion: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    option5: "",
    answer: "",
  };

  private canvas;
  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.attendence = new AttendanceService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    //WEBCAMERA SETTINGS
    const webcamElement = document.getElementById("webcam");
    const canvasElement = document.getElementById("canvas");

    this.webcam = new Webcam(webcamElement, "user", canvasElement);

    this.startWebCam();
    this.loadList();
  }

  unmounted() {
    this.webcam.stop();
  }

  startWebCam() {
    this.webcam
      .start()
      .then(() => {
        this.cameraFound = true;
      })
      .catch(() => {
        this.cameraFound = false;
        this.toast.showInfo("Couldnt able to find camera");
      });
  }

  takePhoto() {
    const picture = this.webcam.snap();
    return picture;
  }

  logout(slotProps) {
    this.attendenceID = slotProps.data.id;
    this.cameraScreenTile = "Logout";
    this.cameraScreenAssociate =
      slotProps.data.employeeFirstname + " " + slotProps.data.employeeName;
  }

  logOutReq() {
    if (this.attendenceID != "") {
      const picture = this.takePhoto();
      if (picture != "") {
        this.attendence
          .logoutAssociate(this.attendenceID, this.associatePIN, picture)
          .then((res) => {
            this.toast.handleResponse(res);
            this.associatePIN = "";
            this.loadList();
            this.login();
          });
      } else {
        this.toast.showInfo(
          "Couldnt able to snap associate image. Please refresh screen and try again"
        );
      }
    } else {
      this.toast.showInfo("Please choose any associate first");
    }
  }

  loginReq() {
    const picture = this.takePhoto();
    if (picture != "") {
      this.attendence.loginAssociate(this.associatePIN, picture).then((res) => {
        this.toast.handleResponse(res);
        this.associatePIN = "";
        this.loadList();
      });
    } else {
      this.toast.showInfo(
        "Couldnt able to snap associate image. Please refresh screen and  try again"
      );
    }
  }

  login() {
    this.cameraScreenTile = "Login";
    this.cameraScreenAssociate = "Associate Login";
    this.takePhoto();
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.searchDialog = false;

    this.attendence.getAssociate(this.searchList).then((data) => {
      const res = this.camelizeKeys(data);

      this.previousDate = res.previousDate;

      this.nextDate = res.nextDate;

      this.title = res.availableFrom;

      this.lists = res.attendenceList;
    });
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm:ss").format("hh:mm A");
    }
  }

  formatAmount(value) {
    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  previewImage(imagePath) {
    this.previewDailog = true;
    this.previewPath = imagePath;
  }

  nextList() {
    this.searchList.filter = "none";
    this.searchList.date1 = this.nextDate;
    this.searchList.date2 = this.nextDate;
    this.loadList();
  }

  previousList() {
    this.searchList.filter = "none";
    this.searchList.date1 = this.previousDate;
    this.searchList.date2 = this.previousDate;
    this.loadList();
  }

  opensearchList() {
    this.searchDialog = true;

    this.attendence.getBranchBasic().then((data) => {
      this.myStores = data.branches_list;
    });
  }

  saveSurvey() {
    this.attendence
      .saveSurvey(
        this.associatePIN,
        this.surveyList.answer,
        this.surveyList.surveyId
      )
      .then((res) => {
        this.toast.handleResponse(res);

        if (res.alert == "info") {
          this.availableSurvey();
        }
      });
  }

  availableSurvey() {
    this.attendence.availableSurvey(this.associatePIN).then((res) => {
      if (res == "no_survey") {
        this.surveyDailog = false;
        this.loginReq();
      } else if (res == "invalid_associate_pin") {
        this.surveyDailog = false;
        this.toast.showWarning("Invalid associate PIN");
      } else {
        if (res.length > 0) {
          this.surveyDailog = true;

          const surveyData = this.camelizeKeys(res[0]);

          this.surveyList.surveyId = surveyData.surveyId;
          this.surveyList.option1 = surveyData.option1;
          this.surveyList.option2 = surveyData.option2;
          this.surveyList.option3 = surveyData.option3;
          this.surveyList.option4 = surveyData.option4;
          this.surveyList.option5 = surveyData.option5;
          this.surveyList.surveyQuestion = surveyData.surveyQuestion;
        } else {
          this.toast.showWarning(
            "Something went wrong please try to refresh and re try"
          );
        }
      }
    });
  }
}
</script>

<style scoped>
.camera-section {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  height: 100vh;
}

.camera-section h6 {
  background-color: #004c97;
  color: #fff;
  padding: 9px 0px;
  margin: -5px -5px 15px -5px;
}

.user-image {
  width: 40px;
  height: 40px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 100%;
}
.user-image:hover {
  cursor: pointer;
}

.preview-image {
  width: 41.5vw;
  border-radius: 5px;
}

.toggleBar {
  background-color: #004c97;
}

.survey-question {
  padding: 10px;
  border-radius: 5px;
  font-size: 22px;
  border: 1px solid #ccc;
}
</style>
