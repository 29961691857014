
import { Options, Vue } from "vue-class-component";
import Associate from "../../service/Associate";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import AssociateDialog from "../../components/AssociateDialog.vue";
@Options({
  components: {
    AssociateDialog,
  },
})
export default class Associates extends Vue {
  private lists = [];
  private keyword = "";
  private associates;
  private toast;
  private submitted = false;
  private showPin = false;
  private permission = "yes";
  private title = "Add Associate";
  private productStatus = "";
  private changePinDialog = false;
  private associateStatus = "";
  private statusDialog = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Associates" },
  ];

  private associate = {
    employeeId: "",
    showEmployeeId: "",
    name: "",
    oldPin: "",
    newPin: "",
    confirmPin: "",
    storeManagerPin: "",
    associatePin: "",
    status: "Active",
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.associates = new Associate();
    this.toast = new Toaster();
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.associate.employeeId = data.employee_id;
    this.associate.name = data.employee_firstname;

    this.productStatus = status;
    this.statusDialog = true;
  }

  //OPEN CHNAGE DIALOG BOX
  openChangeDialog() {
    this.changePinDialog = true;
    this.submitted = false;
  }

  //CHANGE ASSOCIATE PIN
  changePin(e) {
    e.preventDefault();
    this.submitted = true;

    if (this.associate.employeeId) {
      this.associates.changePin(this.associate).then((res) => {
        this.closeDialogBox();
        //SHOW NOTIFICATION
        this.toast.handleResponse(res);
      });
      this.clearAssociatePin();
    }
  }

  //SHOW ASSOCIATE DIALOG BOX
  showAssociatePin(e) {
    this.showPin = true;
    e.preventDefault();
    if (
      this.associate.showEmployeeId != "" &&
      this.associate.storeManagerPin != ""
    ) {
      this.associates.getAssociatePin(this.associate).then((res) => {
        if (res.msg) {
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        } else {
          this.associate.associatePin = res;
        }
      });
    }
  }

  //CLEAR ASSOCIATE PIN SETTINGS DIALOG BOX
  clearAssociatePin() {
    this.associate.employeeId = "";
    this.associate.showEmployeeId = "";
    this.associate.oldPin = "";
    this.associate.newPin = "";
    this.associate.confirmPin = "";
    this.associate.name = "";
    this.associate.storeManagerPin = "";
    this.associate.associatePin = "";
    this.associate.status = "";
  }

  closeDialogBox() {
    this.submitted = false;
    this.changePinDialog = false;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.associate.status = this.productStatus;
    this.associates.changeStatus(this.associate).then((res) => {
      this.loadList();
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    // this.loadList();
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.submitted = true;
    if (this.keyword) {
      this.associates.getItems(this.keyword).then((data) => {
        this.lists = data.search_result;
      });
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.associate.employeeId = data.employee_id;
    this.associateStatus = "updateAssociate";
    this.title = "Update Associate";
  }

  //USED TO UPDATE THE PIN STATUS
  updateAssociateStatus() {
    this.associateStatus = "";
    this.loadList();
  }

  //USED TO OPEN THE ADD DIALOG MODEL
  openDialog() {
    this.associateStatus = "openAssociate";
    this.title = "Add Associate";
  }
}
