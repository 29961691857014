
import { Options, Vue } from "vue-class-component";
import Branch from "../../service/Branch";
import Toaster from "../../helpers/Toaster";
import StorePin from "../../components/StorePin.vue";

@Options({
  components: {
    StorePin,
  },
})
export default class Branches extends Vue {
  private lists = [];
  private branches;
  private productStatus = "";
  private keyword = "";
  private loading = false;
  private productDialog = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Stores" },
  ];
  private storeTypes = [
    {
      value: "HQ",
      name: "HQ",
    },
    {
      value: "Branch",
      name: "Store",
    },
  ];
  private taxCriteria = [
    {
      value: "GST",
      name: "GST",
    },
    {
      value: "GST_PST",
      name: "GST+PST",
    },
    {
      value: "HST",
      name: "HST",
    },
    {
      value: "GST_QST",
      name: "GST + QST",
    },
    {
      value: "SALES TAX",
      name: "SALES TAX",
    },
  ];
  private smsOptions = [
    {
      value: "enable",
      name: "Enable",
    },
    {
      value: "disable",
      name: "Disable",
    },
  ];

  private toast;
  private branchDetails = {
    storeID: 0,
    isAdmin: {},
    storeCode: 0,
    mallName: "",
    telephoneNo: "",
    contactNo: "",
    address: "",
    city: "",
    region: {},
    province: "",
    country: "",
    postalCode: "",
    ll: "",
    costCenter: "",
    regularWeeklyHrs: 44,
    regularDailyHrs: 8,
    statholidayRate: 0,
    overTimeRate: 0,
    storeOpeningDate: "",
    sqft: 80,
    taxCriteria: {},
    gstSalesTax: 0,
    qstPstTax: 0,
    emailAddress: "",
    password: "12345",
    taxNo: "",
    overTime: 0,
    birthDay: 0,
    statHoliday: 0,
    statWork: 0,
    meetCon: 0,
    personal: 0,
    sickHrs: 0,
    vacations: 0,
    breavmt: 0,
    training: 0,
    enableSms: {},
    status: "",
  };

  private dialogTitle;
  private dialogCallback;
  private regionsList;
  private submitted = false;
  private pinDialogStatus = false;
  private storeCode = 0;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.branches = new Branch();
    this.toast = new Toaster();
  }

  //OPNE DIALOG TO CHANGE STORE PIN
  changePin(data) {
    this.pinDialogStatus = true;
    this.storeCode = Number(data.code);
  }

  //USED TO UPDATE THE PIN STATUS
  updateThePinDialogStatus() {
    this.pinDialogStatus = false;
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItem();

    this.submitted = false;
    this.dialogTitle = "Add New Store";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.branchDetails.mallName.trim()) {
      if (this.branchDetails.storeID != 0) {
        this.branches.updateItem(this.branchDetails).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.branches.saveItem(this.branchDetails).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Store";
    this.productDialog = true;
    this.branches.getItem(data).then((res) => {
      if (res.length > 0) {
        res = res[0];

        const isAdmin = this.filterStoreType(res);

        const taxCriteria = this.filtertaxCriteria(res);

        const smsEnabled = this.filterSmsEnabled(res);

        const region = this.filterRegion(res);

        this.branchDetails = {
          isAdmin: isAdmin,
          storeID: Number(res.code),
          storeCode: Number(res.code),
          mallName: res.mall,
          telephoneNo: res.telephone,
          contactNo: res.contact,
          address: res.address,
          city: res.city,
          region: region,
          province: "",
          country: "",
          postalCode: res.postalcode,
          ll: res.ll,
          costCenter: res.cost_center,
          regularWeeklyHrs: Number(res.regular_hrs),
          regularDailyHrs: Number(res.daily_hrs),
          statholidayRate: Number(res.stat_holiday_rate),
          overTimeRate: Number(res.overtime_rate),
          storeOpeningDate: res.service_date,
          sqft: Number(res.sqft),
          taxCriteria: taxCriteria,
          gstSalesTax: Number(res.gst),
          qstPstTax: Number(res.pst),
          emailAddress: res.email_address,
          password: res.branch_password,
          taxNo: res.tax_code,
          overTime: Number(res.overtime),
          birthDay: Number(res.birhtday),
          statHoliday: Number(res.stat_hol),
          statWork: Number(res.statwork),
          meetCon: Number(res.meetcon),
          personal: Number(res.personel),
          sickHrs: Number(res.sickhrs),
          vacations: Number(res.vacations),
          breavmt: Number(res.bereavmt),
          training: Number(res.training),
          enableSms: smsEnabled,
          status: res.status,
        };
      }
    });
  }

  //USED TO FILTER TYPES
  filterStoreType(res) {
    let isAdmin = {};

    this.storeTypes.filter((elem) => {
      if (elem.value == res.is_admin) {
        isAdmin = elem;
      }
    });

    return isAdmin;
  }

  //USED TO FILTER TAX
  filtertaxCriteria(res) {
    let taxCriteria = {};

    this.taxCriteria.filter((elem) => {
      if (elem.value == res.tax_name) {
        taxCriteria = elem;
      }
    });

    return taxCriteria;
  }

  //USED TO FILTER TAX
  filterSmsEnabled(res) {
    let smsEnabled = {};
    this.smsOptions.filter((elem) => {
      if (elem.value == res.enable_email_sms) {
        smsEnabled = elem;
      }
    });

    return smsEnabled;
  }

  //USED TO FILTER TAX
  filterRegion(res) {
    let region = {};

    this.regionsList.filter((elem) => {
      if (elem.region_id == res.province) {
        region = elem;
      }
    });

    return region;
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.branchDetails = {
      isAdmin: data.is_admin,
      storeID: Number(data.code),
      storeCode: Number(data.code),
      mallName: data.mall,
      telephoneNo: data.telephone,
      contactNo: data.contact,
      address: data.address,
      city: data.city,
      region: data.region_id,
      province: data.province_name,
      country: data.country,
      postalCode: data.postalcode,
      ll: data.ll,
      costCenter: data.cost_center,
      regularWeeklyHrs: Number(data.regular_hrs),
      regularDailyHrs: Number(data.daily_hrs),
      statholidayRate: Number(data.stat_holiday_rate),
      overTimeRate: Number(data.overtime_rate),
      storeOpeningDate: data.service_date,
      sqft: Number(data.sqft),
      taxCriteria: data.tax_name,
      gstSalesTax: Number(data.gst),
      qstPstTax: Number(data.pst),
      emailAddress: data.email_address,
      password: data.branch_password,
      taxNo: data.tax_code,
      overTime: Number(data.overtime),
      birthDay: Number(data.birhtday),
      statHoliday: Number(data.stat_hol),
      statWork: Number(data.statwork),
      meetCon: Number(data.meetcon),
      personal: Number(data.personel),
      sickHrs: Number(data.sickhrs),
      vacations: Number(data.vacations),
      breavmt: Number(data.bereavmt),
      training: Number(data.training),
      enableSms: data.enable_email_sms,
      status: data.status,
    };

    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.branchDetails.status = this.productStatus;
    this.branches.changeStatus(this.branchDetails).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.branches.getItems(page).then((res) => {
      this.lists = res.store_list;
      this.totalRecords = res.total_stores_count;
      this.limit = res.limit_per_page;
      this.regionsList = res.regions;
      this.loading = false;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.branches.getSearchedBranches(this.keyword).then((data) => {
        this.lists = data.store_list;
        this.loading = false;
        this.checkPagination = data.pagination;
      });
    }
  }

  clearItem() {
    this.branchDetails = {
      storeID: 0,
      isAdmin: {},
      storeCode: 0,
      mallName: "",
      telephoneNo: "",
      contactNo: "",
      address: "",
      city: "",
      region: {},
      province: "",
      country: "",
      postalCode: "",
      ll: "",
      costCenter: "",
      regularWeeklyHrs: 44,
      regularDailyHrs: 8,
      statholidayRate: 0,
      overTimeRate: 0,
      storeOpeningDate: "",
      sqft: 80,
      taxCriteria: {},
      gstSalesTax: 0,
      qstPstTax: 0,
      emailAddress: "",
      password: "12345",
      taxNo: "",
      overTime: 0,
      birthDay: 0,
      statHoliday: 0,
      statWork: 0,
      meetCon: 0,
      personal: 0,
      sickHrs: 0,
      vacations: 0,
      breavmt: 0,
      training: 0,
      enableSms: {},
      status: "",
    };
  }
}
