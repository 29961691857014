<template>
  <section>
    <nav class="navbar navbar-expand-md navbar-light fixed-top top-bar">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-md-center"
        id="navbarCollapse"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link primary-button" to="/store/dashboard">
              <span class="pi pi-home"></span> Dashboard
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link danger-button" to="/backroom/on-rack">
              <span class="pi pi-clock"></span> On Rack
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link warning-button"
              to="/backroom/on-progress"
            >
              <span class="pi pi-spinner"></span> In Progress
            </router-link>
          </li>
          <li class="nav-item p-mr-1">
            <router-link
              class="nav-link success-button"
              to="/backroom/on-ready"
            >
              <span class="pi pi-star-o"></span> Completed Jobs
            </router-link>
          </li>
          <li class="nav-item">
            <Button
              @click="scanOrder"
              class="nav-link custom-button p-button-primary"
            >
              <span class="pi pi-search"></span> Scan Order
            </Button>
          </li>
          <li class="nav-item">
            <Button
              @click="prepInvoice"
              class="nav-link custom-button p-button-success"
            >
              <span class="pi pi-plus-circle"></span> Prep Invoice
            </Button>
          </li>
        </ul>
      </div>
    </nav>
    <Dialog
      v-model:visible="productDialog"
      :style="{ width: '50vw' }"
      :maximizable="true"
      position="top"
      class="p-fluid"
    >
      <template #header>
        <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
          Scan Complete Invoice OR Sales Item
        </h4>
      </template>
      <div class="p-field">
        <label for="name">Scan Invoice or Item Barcode</label>
        <input
          type="number"
          id="name"
          class="form-control"
          v-model.trim="product.itemID"
          autofocus
          :class="{ 'p-invalid': submitted && !product.itemID }"
        />
        <small class="p-invalid" v-if="submitted && !product.itemID"
          >Barcode is required.</small
        >
      </div>
      <div class="p-field">
        <label for="Priority">Associate PIN</label>
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="product.pin"
        />
        <small class="p-invalid" v-if="submitted && !product.pin"
          >PIN is required.</small
        >
      </div>
      <template #footer>
        <Button
          type="button"
          label="Rack"
          icon="pi pi-bars"
          class="p-button-danger btn-lg"
          @click="itemsAction('rack')"
        />
        <Button
          type="button"
          label="Progress"
          icon="pi pi-spinner"
          class="p-button-warning btn-lg"
          @click="itemsAction('pending')"
        />
        <Button
          type="button"
          label="Ready"
          icon="pi pi-check"
          class="p-button-success btn-lg"
          @click="itemsAction('ready')"
        />
      </template>
    </Dialog>
    <Dialog
      v-model:visible="prepDialog"
      :style="{ width: '70vw' }"
      :maximizable="true"
      position="top"
      class="p-fluid"
    >
      <template #header>
        <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
          Choose Item to Prep
        </h4>
      </template>
      <div class="p-grid p-ai-start vertical-container">
        <div class="p-col-12 p-md-12 p-lg-12">
          <div class="p-field">
            <label for="name">Scan Invoice ID</label>
            <InputText
              id="name"
              v-model.trim="product.itemID"
              required="true"
              @keyup="getPrepReceipt()"
              autofocus
              :class="{ 'p-invalid': submitted && !product.itemID }"
            />
            <small class="p-invalid" v-if="submitted && !product.itemID"
              >Barcode is required.</small
            >
          </div>
        </div>
        <div class="p-col-8 p-md-8 p-lg-8 cover-receipt">
          <ul
            class="visitor list-unstyled list-unstyled-border inv_employee_pickup"
            v-if="OrderServices.length"
          >
            <li class="p-p-0 p-m-0">
              <div class="p-0 media-body">
                <p class="p-m-0">Order ID : {{ OrderDetail.id }}</p>
                <p class="p-m-0">
                  <span class="pull-right">
                    Due : {{ formatDate(OrderDetail.dueDate) }} |
                    {{ formatTime(OrderDetail.dueTime) }}</span
                  >
                </p>
                <p class="p-m-0">
                  <span>Memo : {{ OrderDetail.description }}</span>
                </p>
              </div>
            </li>
            <template v-for="services in OrderServices" :key="services">
              <li class="p-p-0 p-m-0">
                <div class="media-body">
                  <div
                    style="background-color: #c00; color: #fff"
                    class="p-1 pt-2"
                  >
                    Status :
                    <strong class="pull-right"
                      >{{ services.workingStatus.toUpperCase() }}/{{
                        services.checkStatus.toUpperCase()
                      }}</strong
                    >
                  </div>
                </div>
              </li>
              <li class="p-p-1 p-m-0 border-bottom">
                <div class="service_row_cls">
                  <p class="p-m-0 p-p-0">
                    <img
                      class="mr-2 mt-2 mb-2 rounded-circle"
                      width="25"
                      :src="imagePath + services.image"
                      alt="avatar"
                    />
                    {{ services.serviceName }}
                    <i
                      v-if="services.express != 'no'"
                      class="pi pi-circle-on order-express-tag p-mt-2 p-mr-1"
                    ></i>
                  </p>
                  <p class="p-m-0 p-p-1 text-right">
                    Item ID : {{ services.id }}
                  </p>
                  <p class="p-m-0 p-p-0" v-if="services.express != 'no'">
                    Express Memo: {{ formatDate(services.description) }}
                  </p>
                  <p class="p-m-0 p-p-0" v-if="services.express != 'no'">
                    Due Date {{ formatDate(services.dueDate) }} |
                    {{ formatTime(services.dueTime) }}
                  </p>
                </div>
              </li>
              <li
                class="p-p-0 p-m-0 border-bottom"
                v-for="itemList in services.itemList"
                :key="itemList"
              >
                <p class="pt-2">
                  <i class="pi pi-arrow-circle-right"></i>
                  {{ itemList.itemName }}
                  <span class="pull-right">
                    Unit : {{ itemList.itemQty }}x
                    <input
                      type="checkbox"
                      style="width: 25px; height: 25px"
                      @click="addItem(services.id, itemList.salesItemId)"
                    />
                  </span>
                </p>
                <p class="p-m-0 p-p-0" v-if="itemList.tag3 != ''">
                  <i class="pi pi-tags"></i> {{ itemList.tag3 }}
                </p>
              </li>
            </template>
          </ul>
          <div class="load-prep_service-details" v-if="!OrderServices.length">
            <h4 class="p-mt-4">
              No Invoice details
              <i class="pi pi-question" aria-hidden="true"></i>
            </h4>
            <p>Please scan Invoice ID to load invoice services & items.</p>
          </div>
        </div>
        <div class="p-col-4 p-md-4 p-lg-4">
          <div class="p-field">
            <input
              v-model.trim="product.pin"
              type="password"
              placeholder="Associate PIN"
              class="form-control"
              :class="{ 'p-invalid': submitted && !product.pin }"
            />
            <small class="p-invalid" v-if="submitted && !product.pin"
              >PIN is required.</small
            >
          </div>
          <span class="p-buttonset">
            <Button
              label="1"
              @click="numPad(1)"
              class="p-button-primary key-pad-button"
            />
            <Button
              label="2"
              @click="numPad(2)"
              class="p-button-primary key-pad-button"
            />
            <Button
              label="3"
              @click="numPad(3)"
              class="p-button-primary key-pad-button"
            />
          </span>
          <span class="p-buttonset">
            <Button
              label="4"
              @click="numPad(4)"
              class="p-button-primary key-pad-button"
            />
            <Button
              label="5"
              @click="numPad(5)"
              class="p-button-primary key-pad-button"
            />
            <Button
              label="6"
              @click="numPad(6)"
              class="p-button-primary key-pad-button"
            />
          </span>
          <span class="p-buttonset">
            <Button
              label="7"
              @click="numPad(7)"
              class="p-button-primary key-pad-button"
            />
            <Button
              label="8"
              @click="numPad(8)"
              class="p-button-primary key-pad-button"
            />
            <Button
              label="9"
              @click="numPad(9)"
              class="p-button-primary key-pad-button"
            />
          </span>
          <span class="p-buttonset">
            <Button
              label="0"
              @click="numPad(0)"
              class="p-button-primary key-pad-button"
            />
            <Button
              label="x"
              @click="numPad('clear')"
              class="p-button-primary key-pad-button"
            />
            <Button label="-" class="p-button-warning key-pad-button" />
          </span>
        </div>
      </div>
      <template #footer>
        <div class="p-d-flex p-jc-between">
          <Button
            type="button"
            label="Start Prep"
            icon="pi pi-check-circle"
            class="p-button-success btn-lg"
            @click="prepAction('Start Prep')"
          />
          <Button
            type="button"
            label="End Prep"
            icon="pi pi-thumbs-up"
            class="p-button-primary btn-lg"
            @click="prepAction('End Prep')"
          />
        </div>
      </template>
    </Dialog>
    <div style="height: 0.3em; background-color: #eee; margin-top: 9.2vh">
      <ProgressBar
        v-if="progressBar"
        mode="indeterminate"
        style="height: 0.2em"
      />
    </div>
    <router-view :key="path"></router-view>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { publicPath } from "../../../vue.config.js";
import { useStore } from "../../store";
import Backrooom from "../../service/Backrooom";
import Toaster from "../../helpers/Toaster";
import { camelCase } from "lodash";
import moment from "moment";
import router from "../../router";

interface OrderDetails {
  dueDate: string;
  dueTime: string;
  description: string;
  id: string;
}

interface OrderItemList {
  itemName: string;
  itemQty: number;
  tag3: string;
  salesItemId: number;
  slectedItem: string;
}

interface OrderService {
  serviceName: string;
  express: string;
  workingStatus: string;
  checkStatus: string;
  image: string;
  id: string;
  dueDate: string;
  description: string;
  dueTime: string;
  itemList: OrderItemList[];
}

@Options({
  components: {},
})
export default class Backroom extends Vue {
  private imagePath = "";
  private store = useStore();
  private toast;
  private backroomService;
  private path = publicPath;
  private productDialog = false;
  private prepDialog = false;
  private submitted = false;
  private prepOrder = [];
  private product = {
    itemID: "",
    pin: "",
  };

  private selectedItems: string[] = [];

  private OrderDetail: OrderDetails = {
    dueDate: "",
    dueTime: "",
    description: "",
    id: "",
  };

  private OrderServices: OrderService[] = [];
  private OrderItemLists: OrderItemList[] = [];

  created() {
    this.backroomService = new Backrooom();
    this.toast = new Toaster();
    this.imagePath = this.backroomService.getBaseURL() + "uploads/services/";
  }

  clearData() {
    this.product = {
      itemID: "",
      pin: "",
    };
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  scanOrder() {
    this.clearData();
    this.productDialog = true;
  }

  prepInvoice() {
    this.clearData();
    this.prepDialog = true;
  }

  itemsAction(action) {
    const len = this.product.itemID.length;

    if (len == 14 || len == 16) {
      this.submitted = true;
      if (this.product.itemID.trim()) {
        this.backroomService.scanReceipt(this.product, action).then((res) => {
          this.productDialog = false;
          this.submitted = false;
          this.toast.handleResponse(res);
          this.clearData();
          this.redirectPage(action);
        });
      }
    } else {
      this.toast.showWarning(
        "Invalid Bar code Could not process with the scanned code"
      );
    }
  }

  numPad(key) {
    if (key == "clear") {
      this.product.pin = "";
    } else if (key == "ok") {
      alert(this.product.pin);
    } else if (key == "none") {
      //DO NOTHING
    } else {
      this.product.pin = this.product.pin + key;
    }
  }

  getPrepReceipt() {
    this.backroomService.getPrepReceipt(this.product).then((res) => {
      const receiptInfo = this.camelizeKeys(res.order_details);
      const receiptItems = this.camelizeKeys(res.order_items);

      this.OrderDetail.id = receiptInfo.id;
      this.OrderDetail.dueDate = receiptInfo.dueDate;
      this.OrderDetail.dueTime = receiptInfo.dueTime;
      this.OrderDetail.description = receiptInfo.description;
      this.OrderServices = receiptItems;
    });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  fixLength(amount) {
    if (amount != "") {
      amount = amount.toFixed(2);
    }

    return amount;
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }

  addItem(serviceID, ItemsID) {
    const id = serviceID + "_" + ItemsID;

    if (this.selectedItems.includes(id)) {
      this.selectedItems.splice(this.selectedItems.indexOf(id), 1);
    } else {
      this.selectedItems.push(id);
    }
  }

  prepAction(action) {
    this.submitted = true;
    if (this.selectedItems.length > 0) {
      if (this.product.itemID == "" || this.product.pin == "") {
        this.toast.showWarning("Please scan receipt and enter associate PIN");
      } else {
        if (this.product.itemID.trim()) {
          this.backroomService
            .savePrep(this.product, this.selectedItems, action)
            .then((res) => {
              this.prepDialog = false;
              this.submitted = false;
              this.toast.handleResponse(res);
              this.clearData();
            });
        }
      }
    } else {
      this.toast.showWarning("Please choose any item to prep");
    }
  }

  redirectPage(action) {
    if (action == "ready") {
      router.push({ path: "/backroom/on-ready", params: {} });
    } else if (action == "pending") {
      router.push({ path: "/backroom/on-progress", params: {} });
    } else {
      router.push({ path: "/backroom/on-rack", params: {} });
    }
  }
}
</script>

<style scoped>
.p-steps-item {
  background-color: #c00;
}

.navbar {
  padding: 0.2rem 0rem 0rem 0rem;
}
.top-bar {
  border-top: 2px solid #1463a5;
}

.navbar-light {
  z-index: 1;
  background-color: #f7f7f7;
  padding-bottom: 3px;
  border-bottom: 2px solid #ccc;
}

.nav-item {
  width: 170px;
}

.router-link-active {
  border: 3px solid #777 !important;
}

.primary-button {
  background-color: #1463a5;
  text-align: center;
  border-radius: 5px;
  border: 3px solid #fff;
  padding: 12px;
}

.danger-button {
  background-color: #dc3545;
  text-align: center;
  border: 3px solid #fff;
  border-radius: 5px;
  padding: 12px;
}

.warning-button {
  background-color: #ffb209;
  text-align: center;
  border: 3px solid #fff;
  border-radius: 5px;
  padding: 12px;
}

.success-button {
  background-color: #38d250;
  text-align: center;
  border: 3px solid #fff;
  border-radius: 5px;
  padding: 12px;
}

.black-button {
  background-color: #353434;
  text-align: center;
  border-radius: 3px;
  border: 3px solid #fff;
  padding: 12px;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.custom-button {
  padding: 11px;
  margin-top: 2px;
  border: none;
  width: 98%;
}

.load-prep_service-details {
  background-color: #eee;
  height: 61.5vh;
  border: 1px solid #ccc;
  text-align: center;
  color: #333;
  width: 100%;
}

.key-pad-button {
  width: 6vw;
  height: 10vh;
  margin: 1px;
}

.service_row_cls {
  background-color: #74e2e2;
}

.order-express-tag {
  float: right;
  color: red;
}

.border-bottom {
  border-bottom: 1px solid #333;
}

.cover-receipt {
  background-color: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 3px;
}
</style>
