
import { Options, Vue } from "vue-class-component";
import "@/assets/css/backroomStyle.css";
import { useStore } from "../../store";
import Backrooom from "../../service/Backrooom";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import PreviewReceipt from "../../components/PreviewReceipt.vue";
import EmployeeBackroom from "../../components/EmployeeBackroom.vue";
import AssocaiteBackroomPickup from "../../components/AssocaiteBackroomPickup.vue";
import CustomerDialog from "../../components/CustomerDialog.vue";
import { camelCase } from "lodash";

interface OrderItem {
  totalBill: number;
  invType: string;
}

@Options({
  components: {
    PreviewReceipt,
    EmployeeBackroom,
    AssocaiteBackroomPickup,
    CustomerDialog,
  },
})
export default class OnRack extends Vue {
  private store = useStore();
  private previewReceiptStatus = false;
  private receiptID = "";
  private orderType = "";
  private orderStatus = "";
  private orderAction = "";
  private previousWeek = "";
  private totalBill = 0;
  private toast;
  private nextWeek = "";
  private searchDate = "";
  private associateStatus = false;
  private associateOrderPickStatus = false;
  private weekDates = {
    sunday: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
  };
  private orderLists = {};
  private backroomService;
  private customerId = "";
  private customerStatus = "";
  private permission = "";
  private dialogTitle = "";

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.backroomService = new Backrooom();
    this.toast = new Toaster();
  }

  mounted() {
    const fetchDate = "";
    this.loadList(fetchDate);
  }

  loadList(fetchDate) {
    fetchDate = fetchDate.trim();

    this.backroomService.getRackList(fetchDate).then((data) => {
      this.previousWeek = data.previous_week;
      this.nextWeek = data.next_week;
      this.orderLists = data.order_list;

      //ASSINGING WEEK DATES
      this.weekDates.sunday = data.week_dates.sunday;
      this.weekDates.monday = data.week_dates.monday;
      this.weekDates.tuesday = data.week_dates.tuesday;
      this.weekDates.wednesday = data.week_dates.wednesday;
      this.weekDates.thursday = data.week_dates.thursday;
      this.weekDates.friday = data.week_dates.friday;
      this.weekDates.saturday = data.week_dates.saturday;
    });
  }

  //USED TO UPDATE THE PIN STATUS
  updateCustomerStatus() {
    this.customerStatus = "";
  }

  openCustomerProfile(id) {
    this.dialogTitle = "Customer Profile";
    this.customerId = id;
    this.permission = "no";
    this.customerStatus = "updateCustomer";
  }

  nextList() {
    this.searchDate = this.nextWeek;
    this.loadList(this.nextWeek);
  }

  previousList() {
    this.searchDate = this.previousWeek;
    this.loadList(this.previousWeek);
  }

  filterDate() {
    this.loadList(this.searchDate);
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }

  dayName(value) {
    if (value) {
      return moment(String(value)).format("dddd");
    }
  }
  countItems(orderDate) {
    const singleOrder = this.orderLists[orderDate];

    let totalItems = 0;

    if (singleOrder != null) {
      totalItems = Object.keys(singleOrder).length;
    } else {
      totalItems = 0;
    }
    return totalItems;
  }

  countTotalBill(orderDate: string) {
    let totalBill = 0;

    const list = this.orderLists[orderDate];

    if (list != null) {
      const casedList = this.camelizeKeys(list);
      const arr: OrderItem[] = Object.keys(casedList).map((x) => casedList[x]);

      arr.forEach((e) => {
        if (e.invType != "express") {
          totalBill = totalBill + e.totalBill;
        }
      });
    }

    return totalBill.toFixed(2);
  }

  refreshList() {
    this.loadList(this.searchDate);
  }

  previewReceipt(receiptID) {
    this.previewReceiptStatus = true;
    this.receiptID = receiptID;
  }

  updateReceiptStatus() {
    this.receiptID = "";
    this.previewReceiptStatus = false;
  }

  previewAssocaites(receiptID) {
    this.receiptID = receiptID;
    this.associateStatus = true;
  }

  pickupAssocaiteOrder(receiptID, type, orderStatus, orderAction) {
    this.receiptID = receiptID;
    this.orderType = type;
    this.orderStatus = orderStatus;
    this.orderAction = orderAction;
    this.associateOrderPickStatus = true;
  }

  closePreviewAssocaites() {
    this.associateStatus = false;
  }

  closeupdateOrderStatus() {
    this.associateOrderPickStatus = false;
    this.loadList(this.searchDate);
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };
}
