<template>
  <section class="table-response">
    <table class="table table-bordered set-width-orderlist tableHeight">
      <thead class="order-list-head">
        <tr>
          <td class="p-0 m-0" colspan="7">
            <label style="font-size: 26px" class="m-0 p-2 pull-left">
              <Button
                icon="pi pi-arrow-circle-left"
                class="p-button-rounded p-button-outlined"
                @click="previousList"
              />
            </label>
            <label class="mt-2 p-0">
              <input
                type="date"
                class="form-control"
                v-model="searchDate"
                @change="filterDate"
              />
            </label>
            <label style="font-size: 26px" class="m-0 p-2 pull-right">
              <Button
                @click="refreshList"
                icon="pi pi-refresh"
                class="p-button-rounded p-button-warning p-mr-2"
              />
              <Button
                icon="pi pi-arrow-circle-right"
                class="p-button-rounded p-button-outlined"
                @click="nextList"
              />
            </label>
          </td>
        </tr>
        <tr>
          <td v-for="weekDate in weekDates" :key="weekDate">
            <h6><i class="pi pi-calendar"></i> {{ dayName(weekDate) }}</h6>
            <h6>
              <i class="pi pi-money-bill"></i> Pending
              <span class="pull-right">
                $ {{ this.countTotalBill(weekDate) }}
              </span>
            </h6>
            <h6>
              <i class="pi pi-calendar"></i> {{ formatDate(weekDate) }}
              <small class="pull-right">
                <i class="pi pi-shopping-cart"></i>
                {{ countItems(weekDate) }}
              </small>
            </h6>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="weekDate in weekDates" :key="weekDate">
            <ul class="list-unstyled-border">
              <li
                class="p-p-0"
                v-for="order in orderLists[weekDate]"
                :key="order"
              >
                {{ order.date }}
                <div
                  class="p-m-1 text-center"
                  :class="{
                    express: order.inv_type == 'express',
                    quadrat: order.danger == 'over',
                  }"
                >
                  <b> INV : {{ order.invoice_id }} </b>
                </div>
                <div>
                  <b
                    >Customer
                    <i
                      ><span class="pull-right"
                        >{{ order.customer_name }}
                      </span>
                    </i></b
                  >
                </div>
                <div>
                  <b>
                    Prod Date
                    <span class="pull-right">{{
                      formatDate(order.prod_date)
                    }}</span>
                  </b>
                </div>
                <div>
                  <b
                    >Promised Date
                    <span class="pull-right">{{
                      formatDate(order.inv_date)
                    }}</span></b
                  >
                </div>
                <div>
                  <b
                    >Promised Time
                    <span class="pull-right">{{
                      formatTime(order.inv_time)
                    }}</span></b
                  >
                </div>
                <div style="color: #c00">
                  <b>
                    Total Bill
                    <span class="pull-right"
                      >${{ order.total_bill.toFixed(2) }}</span
                    >
                  </b>
                </div>
                <div>
                  <div class="list-button-section">
                    <Button
                      label="Start"
                      class="p-button-raised p-button-success customClass"
                      @click="
                        pickupAssocaiteOrder(
                          order.invoice_id,
                          order.inv_type,
                          'rack',
                          'start'
                        )
                      "
                    />
                    <Button
                      label="Customer"
                      class="p-button-raised p-button-success customClass"
                      @click="openCustomerProfile(order.customer_id)"
                    />
                    <Button
                      label="Preview"
                      class="p-button-raised p-button-primary customClass"
                      @click="previewReceipt(order.invoice_id)"
                    />
                  </div>
                  <p class="text-center end-invoice-order">
                    <i class="pi pi-times"></i>
                    <i class="pi pi-times"></i>
                    <i class="pi pi-times"></i>
                    <i class="pi pi-times"></i>
                    <i class="pi pi-times"></i>
                    <i class="pi pi-times"></i>
                    <i class="pi pi-times"></i>
                    <i class="pi pi-times"></i>
                    <i class="pi pi-times"></i>
                  </p>
                </div>
              </li>
              <li
                class="set-width-orderlist text-center p-mt-5"
                v-if="countItems(weekDate) == 0"
              >
                <img
                  class="rounded-circle"
                  width="25"
                  src="@/assets/images/avatar-6.jpg"
                  alt="avatar"
                />
                <div class="media-body">
                  <div class="media-title">No Orders</div>
                </div>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <PreviewReceipt
      :receiptDetail="{
        status: previewReceiptStatus,
        receiptID: receiptID,
      }"
      v-on:updateReceiptStatus="updateReceiptStatus"
    />
    <EmployeeBackroom
      :associateDetail="{
        status: associateStatus,
        receiptID: receiptID,
      }"
      v-on:updateEmployeeStatus="closePreviewAssocaites"
    />

    <AssocaiteBackroomPickup
      :associateDetail="{
        status: associateOrderPickStatus,
        receiptID: receiptID,
        orderType: orderType,
        orderStatus: orderStatus,
        orderAction: orderAction,
      }"
      v-on:updateOrderStatus="closeupdateOrderStatus"
    />
    <CustomerDialog
      :customerDetail="{
        status: customerStatus,
        customerId: customerId,
        permission: permission,
        title: dialogTitle,
      }"
      v-on:updateCustomerStatus="updateCustomerStatus"
    />
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import "@/assets/css/backroomStyle.css";
import { useStore } from "../../store";
import Backrooom from "../../service/Backrooom";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import PreviewReceipt from "../../components/PreviewReceipt.vue";
import EmployeeBackroom from "../../components/EmployeeBackroom.vue";
import AssocaiteBackroomPickup from "../../components/AssocaiteBackroomPickup.vue";
import CustomerDialog from "../../components/CustomerDialog.vue";
import { camelCase } from "lodash";

interface OrderItem {
  totalBill: number;
  invType: string;
}

@Options({
  components: {
    PreviewReceipt,
    EmployeeBackroom,
    AssocaiteBackroomPickup,
    CustomerDialog,
  },
})
export default class OnRack extends Vue {
  private store = useStore();
  private previewReceiptStatus = false;
  private receiptID = "";
  private orderType = "";
  private orderStatus = "";
  private orderAction = "";
  private previousWeek = "";
  private totalBill = 0;
  private toast;
  private nextWeek = "";
  private searchDate = "";
  private associateStatus = false;
  private associateOrderPickStatus = false;
  private weekDates = {
    sunday: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
  };
  private orderLists = {};
  private backroomService;
  private customerId = "";
  private customerStatus = "";
  private permission = "";
  private dialogTitle = "";

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.backroomService = new Backrooom();
    this.toast = new Toaster();
  }

  mounted() {
    const fetchDate = "";
    this.loadList(fetchDate);
  }

  loadList(fetchDate) {
    fetchDate = fetchDate.trim();

    this.backroomService.getRackList(fetchDate).then((data) => {
      this.previousWeek = data.previous_week;
      this.nextWeek = data.next_week;
      this.orderLists = data.order_list;

      //ASSINGING WEEK DATES
      this.weekDates.sunday = data.week_dates.sunday;
      this.weekDates.monday = data.week_dates.monday;
      this.weekDates.tuesday = data.week_dates.tuesday;
      this.weekDates.wednesday = data.week_dates.wednesday;
      this.weekDates.thursday = data.week_dates.thursday;
      this.weekDates.friday = data.week_dates.friday;
      this.weekDates.saturday = data.week_dates.saturday;
    });
  }

  //USED TO UPDATE THE PIN STATUS
  updateCustomerStatus() {
    this.customerStatus = "";
  }

  openCustomerProfile(id) {
    this.dialogTitle = "Customer Profile";
    this.customerId = id;
    this.permission = "no";
    this.customerStatus = "updateCustomer";
  }

  nextList() {
    this.searchDate = this.nextWeek;
    this.loadList(this.nextWeek);
  }

  previousList() {
    this.searchDate = this.previousWeek;
    this.loadList(this.previousWeek);
  }

  filterDate() {
    this.loadList(this.searchDate);
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }

  dayName(value) {
    if (value) {
      return moment(String(value)).format("dddd");
    }
  }
  countItems(orderDate) {
    const singleOrder = this.orderLists[orderDate];

    let totalItems = 0;

    if (singleOrder != null) {
      totalItems = Object.keys(singleOrder).length;
    } else {
      totalItems = 0;
    }
    return totalItems;
  }

  countTotalBill(orderDate: string) {
    let totalBill = 0;

    const list = this.orderLists[orderDate];

    if (list != null) {
      const casedList = this.camelizeKeys(list);
      const arr: OrderItem[] = Object.keys(casedList).map((x) => casedList[x]);

      arr.forEach((e) => {
        if (e.invType != "express") {
          totalBill = totalBill + e.totalBill;
        }
      });
    }

    return totalBill.toFixed(2);
  }

  refreshList() {
    this.loadList(this.searchDate);
  }

  previewReceipt(receiptID) {
    this.previewReceiptStatus = true;
    this.receiptID = receiptID;
  }

  updateReceiptStatus() {
    this.receiptID = "";
    this.previewReceiptStatus = false;
  }

  previewAssocaites(receiptID) {
    this.receiptID = receiptID;
    this.associateStatus = true;
  }

  pickupAssocaiteOrder(receiptID, type, orderStatus, orderAction) {
    this.receiptID = receiptID;
    this.orderType = type;
    this.orderStatus = orderStatus;
    this.orderAction = orderAction;
    this.associateOrderPickStatus = true;
  }

  closePreviewAssocaites() {
    this.associateStatus = false;
  }

  closeupdateOrderStatus() {
    this.associateOrderPickStatus = false;
    this.loadList(this.searchDate);
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };
}
</script>